import './App.css';
import './style.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './css/style.css';
import DataApp from './DataContext';

import Header from './components/shared/Header';
import Footer from './components/shared/Footer';
import Home from './components/Home';
import Product from './components/Product';
import Category from './components/Category';

import About from './components/About';
import Courses from './components/Courses';
import Admission from './components/Admission';
import Contact from './components/Contact';

function App() {

  return (
    <>
      <BrowserRouter>
        <DataApp>
          <Header />
          <Routes>
            <Route path='/home' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/courses' element={<Courses />} />
            <Route path='/admission' element={<Admission />} />
            <Route path='/contact' element={<Contact />} />
            {/* <Route path='/cart' element={<Home />} />
            <Route path='/checkout' element={<Home />} />
            <Route path='/ordersuccess' element={<Home />} />
            <Route path='/category/:categoryid' element={<Category />} />
            <Route path='/subcategory/:subcategoryid' element={<Home />} />
            <Route path='/product/:productid' element={<Product />} /> */}
            <Route path='/' element={<Home />} />
          </Routes>
          {/* <Home /> */}
          <Footer />
        </DataApp>

      </BrowserRouter>
    </>
  );
}

export default App;
