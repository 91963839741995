import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <>





            {/* <div id="preloader">
        <div class="preloader">
            <span></span>
            <span></span>
        </div>
    </div> */}


            <button class="scroll-top scroll-to-target" data-target="html">
                <i class="far fa-angle-double-up"></i>
            </button>


            <div class="tpoffcanvas-area">
                <div class="tpoffcanvas">
                    <div class="tpoffcanvas__close-btn">
                        <button class="close-btn"><i class="fal fa-times"></i></button>
                    </div>
                    <div class="tpoffcanvas__logo">
                        <a href="index.html">
                            <img src="assets/img/logo/white-logo.png" alt="" />
                        </a>
                    </div>
                    <div class="tpoffcanvas__title">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima incidunt eaque a cumque, porro maxime autem sed.</p>
                    </div>
                    <div class="tp-main-menu-mobile d-xl-none"></div>
                    <div class="tpoffcanvas__contact-info">
                        <div class="tpoffcanvas__contact-title">
                            <h5>Contact us</h5>
                        </div>
                        <ul>
                            <li>
                                <i class="fa-light fa-location-dot"></i>
                                <a href="https://www.google.com/maps/@23.8223586,90.3661283,15z" target="_blank">Melbone st, Australia, Ny 12099</a>
                            </li>
                            <li>
                                <i class="fas fa-envelope"></i>
                                <a href="https://template.wphix.com/cdn-cgi/l/email-protection#5a2935363b283f3e3d3f1a3d373b333674393537"><span class="__cf_email__" data-cfemail="7f0b171a121a0f0a0d1a3f18121e1613511c1012">[email&#160;protected]</span></a>
                            </li>
                            <li>
                                <i class="fal fa-phone-alt"></i>
                                <a href="tel:+48555223224">+48 555 223 224</a>
                            </li>
                        </ul>
                    </div>
                    <div class="tpoffcanvas__input">
                        <div class="tpoffcanvas__input-title">
                            <h4>Get UPdate</h4>
                        </div>
                        <form action="#">
                            <div class="p-relative">
                                <input type="text" placeholder="Enter mail" />
                                <button>
                                    <i class="fas fa-paper-plane"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="tpoffcanvas__social">
                        <div class="social-icon">
                            <a href="#"><i class="fab fa-twitter"></i></a>
                            <a href="#"><i class="fab fa-instagram"></i></a>
                            <a href="https://www.facebook.com/profile.php?id=100063958904269&mibextid=LQQJ4d" target='_blank'><i class="fab fa-facebook-f"></i></a>
                            <a href="#"><i class="fab fa-pinterest-p"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="body-overlay"></div>


            <header class="tp-header-height">

                <div class="tp-header-top-2__area theme-bg tp-header-2__plr">
                    <div class="tp-header-top-2__space">
                        <div class="container-fluid">
                            <div class="row align-items-center">
                                <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-7 col-sm-7">
                                    <div class="tp-header-top-2__left-box text-center text-md-start">
                                        <a href="tel:+84665678899">9430535356, 9431400242</a>
                                        <a href="https://template.wphix.com/cdn-cgi/l/email-protection#11657974616463746251626461617e63653f727e7c">
                                            <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.7536 7.63437C8.38042 7.91095 7.94694 8.05715 7.50001 8.05715C7.05312 8.05715 6.61964 7.91095 6.24646 7.63437L0.0998732 3.07875C0.0657716 3.05348 0.0325489 3.02713 0 3.00003V10.4649C0 11.3208 0.624757 12 1.38085 12H13.6191C14.389 12 15 11.3055 15 10.4649V3C14.9674 3.02716 14.9341 3.05358 14.8999 3.07888L8.7536 7.63437Z" fill="white" />
                                                <path d="M0.587402 2.54786L6.73397 6.76079C6.96665 6.92027 7.23331 7 7.49997 7C7.76666 7 8.03335 6.92024 8.26603 6.76079L14.4126 2.54786C14.7804 2.29591 15 1.87424 15 1.41914C15 0.636605 14.3808 0 13.6196 0H1.38038C0.619248 3.01194e-05 0 0.636635 0 1.41989C0 1.87424 0.219609 2.29591 0.587402 2.54786Z" fill="white" />
                                            </svg>

                                            <span class="__cf_email__" >info.aneducare@gmail.com</span></a>
                                    </div>
                                </div>
                                <div class="col-xxl-4 col-xl-5 d-none d-xl-block">
                                    <div class="tp-header-top-2__text text-xxl-start text-xl-center text-end">
                                        <span>&nbsp;</span>
                                    </div>
                                </div>
                                <div class="col-xxl-4 col-xl-3 col-lg-6 col-md-5 col-sm-5 d-none d-sm-block">
                                    <div class="tp-header-top-2__right-box text-end">
                                        <ul>
                                            <li>
                                                <div class="tp-header-top-2__social-box text-end">
                                                    <a href="https://www.facebook.com/profile.php?id=100063958904269&mibextid=LQQJ4d" target='_blank'><i class="fa-brands fa-facebook-f"></i></a>
                                                    <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                                    <a href="#"><i class="fa-brands fa-instagram"></i></a>
                                                    <a href="#"><i class="fa-brands fa-youtube"></i></a>
                                                </div>
                                            </li>
                                            {/* <li>
                                        <div class="tp-header-top-2__value d-none d-md-block">
                                            <ul>
                                               <li>
                                                  <a id="tp-header-top-2__value-toogle" href="javascript:void(0)">
                                                     USD
                                                     <span>
                                                        <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.37213 0.917894L8.11008 0.638679C8.02761 0.55148 7.91788 0.503441 7.80061 0.503441C7.68341 0.503441 7.57355 0.55148 7.49108 0.638679L4.50179 3.80193L1.50905 0.635169C1.42671 0.54797 1.31685 0.5 1.19965 0.5C1.08244 0.5 0.972524 0.54797 0.890117 0.635169L0.628001 0.912663C0.457333 1.09312 0.457333 1.38706 0.628001 1.56752L4.19122 5.35148C4.27356 5.43861 4.38328 5.5 4.50153 5.5H4.50289C4.62016 5.5 4.72989 5.43854 4.81223 5.35148L8.37213 1.57777C8.4546 1.49064 8.49987 1.37103 8.5 1.24701C8.5 1.12292 8.4546 1.00496 8.37213 0.917894Z" fill="white"/>
                                                            </svg>
                                                            
                                                     </span>
                                                  </a>
                                                  <ul class="tp-header-top-2__value-submenu">
                                                     <li>
                                                        <a href="#">USD</a>
                                                     </li>
                                                     <li>
                                                        <a href="#">YEAN</a>
                                                     </li>
                                                     <li>
                                                        <a href="#">EURO</a>
                                                     </li>
                                                  </ul>
                                               </li>
                                            </ul>
                                         </div>
                                    </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="header-sticky" class="tp-header-2__area tp-header-2__space tp-header-2__plr">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-xl-2 col-lg-6 col-md-4 col-6">
                                <div class="tp-header-2__logo">
                                    <Link to="/home">
                                        {/* <img src="assets/img/logo/logo.png" alt=""/> */}
                                        A N Educare
                                    </Link>
                                </div>
                            </div>
                            <div class="col-xl-7 d-none d-xl-block">
                                <div class="tp-header-2__main-menu tp-header__main-menu">
                                    <nav class="tp-main-menu-content">

                                        <ul>
                                            <li class="has-dropdown">
                                                <Link to="/home">Home</Link>
                                            </li>
                                            <li class="has-dropdown">
                                                <Link to="/about">About</Link>
                                            </li>
                                            <li class="has-dropdown">
                                                <Link to="/courses">Courses</Link>
                                            </li>
                                            <li class="has-dropdown">
                                                <Link to="/admission">Admission</Link>
                                            </li>
                                            <li>
                                                <Link to="/contact">Contact</Link>
                                            </li>
                                        </ul>

                                        {/* <ul>
                                    <li class="has-dropdown">
                                        <a href="index.html">Home</a>
                                        <div class="tp-submenu submenu has-homemenu">
                                            <div class="row gx-6 row-cols-1 row-cols-md-2 row-cols-xl-5">
                                               <div class="col homemenu">
                                                  <div class="homemenu-thumb mb-15">
                                                     <img src="assets/img/menu/home-1.jpg" alt=""/>
                                                     <div class="homemenu-btn">
                                                        <a class="tp-menu-btn" href="index.html">View Demo</a>
                                                     </div>
                                                  </div>
                                                  <div class="homemenu-content text-center">
                                                     <h4 class="homemenu-title">
                                                        <a href="index.html">Home 01</a>
                                                     </h4>
                                                  </div>
                                               </div>
                                               <div class="col homemenu">
                                                  <div class="homemenu-thumb mb-15">
                                                     <img src="assets/img/menu/home-2.jpg" alt=""/>
                                                     <div class="homemenu-btn">
                                                        <a class="tp-menu-btn" href="index-2.html">View Demo</a>
                                                     </div>
                                                  </div>
                                                  <div class="homemenu-content text-center">
                                                     <h4 class="homemenu-title">
                                                        <a href="index-2.html">Home 02</a>
                                                     </h4>
                                                  </div>
                                               </div>
                                               <div class="col homemenu">
                                                  <div class="homemenu-thumb mb-15">
                                                     <img src="assets/img/menu/home-3.jpg" alt=""/>
                                                     <div class="homemenu-btn">
                                                        <a class="tp-menu-btn" href="index-3.html">View Demo</a>
                                                     </div>
                                                  </div>
                                                  <div class="homemenu-content text-center">
                                                     <h4 class="homemenu-title">
                                                        <a href="index-3.html">Home 03</a>
                                                     </h4>
                                                  </div>
                                               </div>
                                               <div class="col homemenu">
                                                  <div class="homemenu-thumb mb-15">
                                                     <img src="assets/img/menu/home-4.jpg" alt=""/>
                                                     <div class="homemenu-btn">
                                                        <a class="tp-menu-btn" href="index-4.html">View Demo</a>
                                                     </div>
                                                  </div>
                                                  <div class="homemenu-content text-center">
                                                     <h4 class="homemenu-title">
                                                        <a href="index-4.html">Home 04</a>
                                                     </h4>
                                                  </div>
                                               </div>
                                               <div class="col homemenu">
                                                  <div class="homemenu-thumb mb-15">
                                                     <img src="assets/img/menu/home-5.jpg" alt=""/>
                                                     <div class="homemenu-btn">
                                                        <a class="tp-menu-btn" href="index-5.html">View Demo</a>
                                                     </div>
                                                  </div>
                                                  <div class="homemenu-content text-center">
                                                     <h4 class="homemenu-title">
                                                        <a href="index-5.html">Home 05</a>
                                                     </h4>
                                                  </div>
                                               </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="has-dropdown">
                                        <a href="#">pages</a>
                                        <ul class="submenu tp-submenu">
                                            <li><a href="team.html">Team</a></li>
                                            <li><a href="project.html">project</a></li>
                                            <li><a href="project-details.html">project details</a></li>
                                            <li><a href="shop.html">shop</a></li>
                                            <li><a href="shop-details.html">shop details</a></li>
                                            <li><a href="cart.html">cart</a></li>
                                            <li><a href="checkout.html">checkout</a></li>
                                            <li><a href="faq.html">faq</a></li>
                                            <li><a href="login.html">login</a></li>
                                            <li><a href="register.html">register</a></li>
                                            <li><a href="404.html">404</a></li>
                                        </ul>
                                    </li>
                                    <li class="has-dropdown">
                                        <a href="service.html">services</a>
                                        <ul class="submenu tp-submenu">
                                            <li><a href="service.html">service</a></li>
                                            <li><a href="service-details.html">service details</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="about-us.html">about</a>
                                    </li>
                                    <li class="has-dropdown">
                                        <a href="blog.html">news</a>
                                        <ul class="submenu tp-submenu">
                                            <li><a href="blog.html">blog</a></li>
                                            <li><a href="blog-details.html">blog details</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="contact.html">contact</a>
                                    </li>
                                </ul> */}
                                    </nav>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-6 col-md-8 col-6">
                                <div class="tp-header-2__right-box text-end">
                                    <ul>

                                        <li>
                                            <div class="tp-header__chat-box d-none d-xxl-block">
                                                <a href="contact.html">
                                                    WhatsApp Icon
                                                    {/* <span>
                                                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_37_410)">
                                                                <path
                                                                    d="M21.5648 16.6095C23.7874 13.4911 23.4476 9.21882 20.4119 6.43587C19.0249 5.16435 17.2566 4.37279 15.3465 4.15172C15.3346 4.13708 15.3224 4.12257 15.3091 4.10869C13.6474 2.38257 11.26 1.39258 8.75923 1.39258C4.00025 1.39258 0 4.9384 0 9.47852C0 11.0703 0.494455 12.5963 1.43373 13.9141L0.121604 18.0338C0.039127 18.2927 0.120256 18.5757 0.327301 18.7517C0.534705 18.928 0.827281 18.9618 1.06901 18.8389L5.05928 16.8099C5.87875 17.1628 6.74772 17.3943 7.64759 17.4996C9.38346 19.3251 11.7837 20.2598 14.2393 20.2598C15.5158 20.2598 16.7877 19.9996 17.9391 19.5052L21.9296 21.5342C22.0262 21.5834 22.1308 21.6074 22.2348 21.6074C22.6895 21.6074 23.0155 21.1643 22.877 20.7291L21.5648 16.6095ZM5.33124 15.4541C5.14252 15.3644 4.92299 15.3674 4.73679 15.4621L1.89472 16.9072L2.8228 13.9933C2.89036 13.7812 2.84881 13.5494 2.71171 13.374C1.81929 12.2316 1.34757 10.8846 1.34757 9.47852C1.34757 5.76303 4.67241 2.74023 8.75923 2.74023C10.405 2.74023 11.9893 3.23882 13.2745 4.13668C8.94637 4.57188 5.48011 7.95243 5.48011 12.1738C5.48011 13.4868 5.81666 14.7508 6.45119 15.8833C6.06701 15.7689 5.69286 15.6258 5.33124 15.4541ZM18.2618 18.1574C18.0783 18.0641 17.8586 18.0585 17.6673 18.1494C16.6168 18.6484 15.4315 18.9121 14.2393 18.9121C10.1525 18.9121 6.82768 15.8893 6.82768 12.1738C6.82768 8.45834 10.1525 5.43555 14.2393 5.43555C18.3262 5.43555 21.651 8.45834 21.651 12.1738C21.651 13.5799 21.1793 14.9269 20.2868 16.0692C20.1498 16.2446 20.1082 16.4764 20.1757 16.6886L21.1038 19.6025L18.2618 18.1574Z"
                                                                    fill="white" />
                                                                <path
                                                                    d="M11.4993 12.8477C11.8714 12.8477 12.1731 12.546 12.1731 12.1738C12.1731 11.8017 11.8714 11.5 11.4993 11.5C11.1271 11.5 10.8254 11.8017 10.8254 12.1738C10.8254 12.546 11.1271 12.8477 11.4993 12.8477Z"
                                                                    fill="white" />
                                                                <path
                                                                    d="M14.1943 12.8477C14.5665 12.8477 14.8682 12.546 14.8682 12.1738C14.8682 11.8017 14.5665 11.5 14.1943 11.5C13.8222 11.5 13.5205 11.8017 13.5205 12.1738C13.5205 12.546 13.8222 12.8477 14.1943 12.8477Z"
                                                                    fill="white" />
                                                                <path
                                                                    d="M16.8896 12.8477C17.2618 12.8477 17.5635 12.546 17.5635 12.1738C17.5635 11.8017 17.2618 11.5 16.8896 11.5C16.5175 11.5 16.2158 11.8017 16.2158 12.1738C16.2158 12.546 16.5175 12.8477 16.8896 12.8477Z"
                                                                    fill="white" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_37_410">
                                                                    <rect width="23" height="23" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </span> */}
                                                </a>
                                            </div>
                                        </li>
                                        <li class="d-xl-none">
                                            <div class="tp-header-2__bar">
                                                <button class="tp-menu-bar">
                                                    <i class="fa-solid fa-bars"></i>
                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>




            {/* <div class="offcanvas-menu-wrapper">
                <div class="offcanvas__close">+</div>
                <ul class="offcanvas__widget">
                    <li><span class="icon_search search-switch"></span></li>
                    <li><a href="#"><span class="icon_heart_alt"></span>
                        <div class="tip">2</div>
                    </a></li>
                    <li><a href="#"><span class="icon_bag_alt"></span>
                        <div class="tip">2</div>
                    </a></li>
                </ul>
                <div class="offcanvas__logo">
                    <a href="index-2.html"><img src="img/logo.png" alt /></a>
                </div>
                <div id="mobile-menu-wrap"></div>
                <div class="offcanvas__auth">
                    <a href="#">Login</a>
                    <a href="#">Register</a>
                </div>
            </div>

            <header class="header">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-3 col-lg-2">

                            <div class="header__right topbar__left">
                                Support : product@sattiyas.com
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-7">
                            <nav class="header__menu topbar__menu text-center">
                                Big sale offer with 50% | New arrival item for you
                                <ul>
                                    <li class="active"><a href="index-2.html">Home</a></li>
                                    <li><a href="#">Women’s</a></li>
                                    <li><a href="#">Men’s</a></li>
                                    <li><a href="shop.html">Shop</a></li>
                                    <li><a href="#">Pages</a>
                                        <ul class="dropdown">
                                            <li><a href="product-details.html">Product Details</a></li>
                                            <li><a href="shop-cart.html">Shop Cart</a></li>
                                            <li><a href="checkout.html">Checkout</a></li>
                                            <li><a href="blog-details.html">Blog Details</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="blog.html">Blog</a></li>
                                    <li><a href="contact.html">Contact</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div class="col-lg-3">
                            <div class="header__right topbar__right">
                                Mon-Fri Open : 11:00 - 19:00
                            </div>
                        </div>
                    </div>
                    <div class="canvas__open">
                        <i class="fa fa-bars"></i>
                    </div>
                </div>
            </header> */}
        </>
    )
}

export default Header
